var right_div_height;
var Wheight = jQuery(window).height();
var windowWidth = window.innerWidth;
var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

function footerHeight() {
    var footerh = jQuery('.footer').outerHeight();
    jQuery('#inner_wrapper').css('paddingBottom', (footerh));
}

jQuery(window).resize(function() {
    windowWidth = window.innerWidth;
    footerHeight();
//    equalHeightTab();
});

jQuery(document).ready(function($) {
    right_div_height = jQuery(".right_side").height();

    // left menu
    if (jQuery(".left_side ul.left_menu li").length) {
        jQuery(".left_side ul.left_menu li").each(function() {
            if (jQuery(this).closest("li").children("ul").length) {
                // the clicked on <li> has a <ul> as a direct child
                jQuery(this).closest("li").addClass("li_subchild");
                if (jQuery(this).closest("li").find("ul li.current_page").length) {
                	jQuery(this).closest("li").addClass("open");
                	jQuery(this).closest("li").children("ul").show();
                }
                jQuery(this).closest("li").children("a").append("<span class='caret'></span>");
                jQuery(this).closest("li").children().eq(0).attr('href', '#');
                jQuery(this).closest("li").removeClass("current_page");
            }
        });
    }

    if (jQuery(".left_side ul.left_menu").length) {
        jQuery(".left_side ul.left_menu li.li_subchild a").on('click', function(event) {
        	if (jQuery(this).attr("href") == "#")
        		event.preventDefault();
            if (jQuery(this).parent("li").hasClass("open")) {
                jQuery(this).parent("li").removeClass('open');
                jQuery(this).next("ul.sec_lev_menu").slideUp(600, function() {
                    var ul_height = jQuery(".left_side").innerHeight();
                    // alert(ul_height);
                    if (ul_height > right_div_height) {
                        jQuery(".right_side .white_bg").css('min-height', ul_height);
                    } else {
                        jQuery(".right_side .white_bg").removeAttr("style");
                    }
                });
            } else {
                if (jQuery(this).parent().parent().parent("li").hasClass("open")) {
                } else {
                    jQuery(".left_side ul.left_menu li.li_subchild").removeClass('open');
                    jQuery(".left_side ul.left_menu li.li_subchild a").next("ul.sec_lev_menu").slideUp(600, function() {
                        var ul_height = jQuery(".left_side").innerHeight();
                        // alert(ul_height);
                        if (ul_height > right_div_height) {
                            jQuery(".right_side .white_bg").css('min-height', ul_height);
                        } else {
                            jQuery(".right_side .white_bg").removeAttr("style");
                        }
                    });
                }
                jQuery(this).parent("li").addClass('open');
                jQuery(this).next("ul.sec_lev_menu").slideDown(600, function() {
                    var ul_height = jQuery(".left_side").innerHeight();
                    // alert(ul_height);
                    if (ul_height > right_div_height) {
                        jQuery(".right_side .white_bg").css('min-height', ul_height);
                    }
                });
            }
        });
    }

    if (jQuery(".left_side").length && jQuery(".right_side .white_bg").length) {
    	var ul_height = jQuery(".left_side").innerHeight();
        if (ul_height > right_div_height) {
            jQuery(".right_side .white_bg").css('min-height', ul_height);
        }
    }

    //dataTable js
    if (jQuery("#entrie_table").length) {
        jQuery('#entrie_table').dataTable({
            searching: false,
            paging: true,
            aaSorting: [],
                    // ordering:  false
        });
    }
    if (jQuery("#stats_table").length) {
        jQuery('#stats_table').dataTable({
            searching: false,
            paging: false,
            aaSorting: [],
//            ordering:  false,
        });
    }

    // tel tag
    if (navigator.userAgent.match(/(iPhone|Android|BlackBerry|iPod|iPad)/)) {
        //this is the phone & tab

    } else {
        //this is the browser
        jQuery('a[href^=tel]').click(function(e) {
            e.preventDefault();
        });
    }
    //equal height

    if (windowWidth > 991) {
        if (jQuery(".heder_bottom ul.menu  li").length) {
            jQuery('.heder_bottom ul.menu  li').mouseover(function() {
                jQuery('.heder_bottom ul.menu  li').each(function() {
                    jQuery(this).addClass('remove_border');
                });
                jQuery(this).removeClass('remove_border');
            });
            jQuery('.heder_bottom ul.menu').mouseout(function() {
                jQuery('.heder_bottom ul.menu  li').each(function() {
                    jQuery(this).removeClass('remove_border');
                });
            });
        }
    }

    if (isMobile.any()) {
        // mobile menu
        jQuery(".mobile_menu_btn").on('click', function() {
            // jQuery(".overlay").show();
            jQuery("#mobile_menu").addClass("open_menu");
            jQuery("html, body").addClass("overflow-hidden");
        });
        jQuery(".menu_close_btn").on('click', function() {
            // jQuery(".overlay").hide();
            jQuery("#mobile_menu").removeClass("open_menu");
        });
    } else {
        // mobile menu
        jQuery(".mobile_menu_btn").on('click', function() {
            // jQuery(".overlay").show();
            jQuery("#mobile_menu").addClass("open_menu");
			jQuery("html, body").addClass("overflow-hidden");
        });
        jQuery(".menu_close_btn").on('click', function() {
            // jQuery(".overlay").hide();
            jQuery("#mobile_menu").removeClass("open_menu");
			jQuery("html, body").removeClass("overflow-hidden");
        });
        // jQuery(".overlay").on('click', function() {
        //     jQuery(".overlay").hide();
        //     jQuery(".mobile_menu").removeClass("open_menu");
        //     jQuery(".mobile_menu").addClass("close_menu");
        // });
    }

    // Client-Editable area creation.
	if (admin == true) {
		$(".client-editable").not('.handled').each(function() {
			var area = $(this);
			var nid = area.attr('data-nid');
			if (nid) {
				var link = $('<a class="client-editable-link" href="/node/' + nid.toString() + '/edit">Edit</a>');
				area.append(link).addClass('handled');
			}
		});
	} else {
		$('.client-editable').removeClass('client-editable');
	}

	if ($(".fancybox").length) {
		$(".fancybox").fancybox({
			helpers: {overlay: {locked: false}},
		});
	}

	$('ul.dropdown-menu [data-toggle=dropdown]').on('click', function(event) {
        // Avoid following the href location when clicking
        event.preventDefault();
        // Avoid having the menu to close when clicking
        event.stopPropagation();
        if ($(this).parent().hasClass('open')) {
        	$('ul.dropdown-menu [data-toggle=dropdown]').parent().removeClass('open');
        } else {
        	$('ul.dropdown-menu [data-toggle=dropdown]').parent().removeClass('open');
        	$(this).parent().addClass('open');
        }
    });
    if ($('#results_table table').length > 0){
        $('#results_table table').cardtable({'headIndex': 'views-field-field-horse', 'myClass': 'cardtable stacktable small-only'});
        $('#results_table').css('visibility', 'visible');
        $('#results_table .cardtable.stacktable.small-only table').each(function(index, el) {
            var horsename = $(this).find('.views-field-field-horse')[0].innerHTML;
            $(this).prepend('<div class="title_text">'+ horsename +'</div>');
        });

        $("#results_table .cardtable.stacktable.small-only table").after("<hr>");

    }

});

jQuery(window).load(function() {
    footerHeight();
});
