
const $ = jQuery;


import './bootstrap';
import './site';
import { Drilldown } from 'foundation-sites';

if($('.drilldown').length > 0){
	const $dilldown = new Drilldown($('.drilldown'));

	$(document).on('open.zf.drilldown',function(e){
		if ($('.is-drilldown-submenu-parent a').length > 0) {
			let submenuHeight = $(e.currentTarget.activeElement).parent().find('.submenu').first().outerHeight();
			$(e.currentTarget.activeElement).closest('.is-drilldown').height(submenuHeight);
			if ($('.icon-Mobile-Nav-Icon-Back').length > 0){
				$('.icon-Mobile-Nav-Icon-Back').addClass('reveal');
			}
		}
	});

	if ($('.icon-Mobile-Nav-Icon-Back').length > 0){
		$('.icon-Mobile-Nav-Icon-Back').click(function(){
			// find the deepest open menu and get the back button from it and click it
			var clickedBack = $('.menu .is-active').last().find('.js-drilldown-back').first().click();
			// get the "to be" revealed ul and get its height
			var parentUl = $('.menu .is-active').last().find('.js-drilldown-back').first().parent().parent().parent();
			var height = parentUl.outerHeight();
			// set the height
			$('.is-drilldown').height(height);
			if (parentUl.hasClass('drilldown')){
				//if its the main parent then hide the back button
				$('.icon-Mobile-Nav-Icon-Back').removeClass('reveal');
			}
		});
	}
}

// click the submit button when the search glass is clicked
var search = $('#submit-search');
if ($('.menu-wrapper form').length > 0 && search.length > 0){
	var submit = $('.menu-wrapper form').first().find('[type="submit"]');
	if (submit.length > 0){
		search.click(function(){
			submit.click();
		});
	}
}
